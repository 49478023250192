.nft-card {
  display: grid;
  grid-template-rows: minmax(max-content, 150px) auto min-content;
  gap: 0.5rem;
  height: 100%;
  color: #000;

  @media (min-width: 768px) {
    grid-template-rows: minmax(200px, 1fr) auto min-content;
  }

  &-wrapper {
    position: relative;
    background-color: var(--card-bg-color);
    box-shadow: 0 3px 20px 0px var(--primary-l-color);
    padding: 0.75rem;
    border-radius: 1rem;
    flex-grow: 1;
    flex-basis: 0;
    align-self: stretch;
    justify-self: center;
    max-width: 200px;
    width: 100%;

    @media (min-width: 767px) {
      min-width: 250px;
      max-width: 250px;
    }

    &.isFeatured {
      border: 0.2rem solid purple;
      border-image-width: 0.15rem;
      border-image-outset: 0.5rem 0.5rem;
      border-image-repeat: round;
      border-image-slice: 1 1;

      & .featured-flag {
        position: absolute;
        left: -1.55rem;
        top: -0.65rem;
        margin: 0;
        padding: 0.25rem 0rem 0.25rem 0.4rem;

        & svg {
          font-size: 1rem;

          @media (min-width: 767px) {
            font-size: 1.5rem;
          }
        }

        & span {
          font-size: 0.75rem;
          line-height: normal;
          letter-spacing: 0.05rem;

          @media (min-width: 767px) {
            font-size: 1rem;
            letter-spacing: 0.1rem;
          }
        }

        &::before {
          height: 0;
          width: 0;
          top: 1.55rem;
          left: 0;
          border-radius: 0.5rem;
          border-top: 0.65rem solid var(--primary-color);
          border-left: 0.85rem solid transparent;

          @media (min-width: 767px) {
            top: 1.95rem;
          }
        }

        &::after {
          right: -0.75rem;
          border-top: 0.9rem solid transparent;
          border-bottom: 0.8rem solid transparent;
          border-left: 0.8rem solid rgba(230, 104, 203, 0.75);

          @media (min-width: 767px) {
            border-top: 1rem solid transparent;
            border-bottom: 1rem solid transparent;
            border-left: 0.8rem solid rgba(230, 104, 203, 0.75);
          }
        }
      }
    }
  }

  & .header {
    position: relative;

    & .media {
      position: relative;
      height: 100%;
      justify-content: center;
      align-items: center;

      & img,
      video {
        min-width: 100px;
        height: max-content;
        width: 100%;
        max-height: 100%;
        border-radius: 12px;
        object-fit: contain;
        object-position: 50% 33%;

        max-width: 175px;
      }

      & .image-link {
        position: relative;
      }
    }
  }

  & .nft-standard-logo {
    position: relative;
    top: 0.5rem;
    left: 0.25rem;
    max-width: 25px;
    height: auto;
  }

  & .body {
    display: grid;
    align-items: center;
    height: 100%;

    & .title {
      font-weight: 700;
      font-size: 1rem;
      margin: 0;
      word-break: break-all;

      &-wrapper {
        position: relative;

        & hr {
          margin: 0.25rem 0;
          width: 33%;
          border-color: #131313;
          border-style: solid;
          border-width: 1px;
        }
      }

      & a {
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
      }
    }

    & .collection-info {
      margin: 0.25rem 0 0.5rem;
      font-style: italic;
      font-size: 0.9rem;
    }

    & .author-wrapper {
      position: relative;

      .author {
        &-name {
          font-weight: 500;
          color: var(--tertiary-color);
        }

        .image {
          display: block;
          margin: 0 auto;
          width: 35px;
          height: 35px;
        }
      }
    }

    & .label {
      font-size: 0.8rem;
      margin: 0 0 0.25rem;
    }

    & .nft-info {
      margin: 0;
      word-break: break-all;
    }
  }

  & .footer {
    align-self: flex-end;
    justify-self: center;
    padding-top: 0.5rem;

    & .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0.75rem 1rem;

      & span {
        font-size: 0.8rem;
      }
    }
  }

  & .save {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
  }

  &.collection {
    top: 0;
    gap: 0.25rem;
    transition: all 300ms;

    & .save {
      top: -0.3rem;
      right: -0.3rem;
    }

    & .body {
      & .title {
        text-align: center;
        margin: 0 auto;
        font-weight: 700;
        color: #1f1f1f;
        text-shadow: 1px 1px 2px rgb(58 44 195 / 15%);
        max-width: 175px;

        &-wrapper {
          & hr {
            margin: 0.25rem auto 0;
          }
        }
      }

      & .author-wrapper {
        order: -1;
        margin-top: -4rem;
        top: 0rem;
        transition: top 700ms;


        & .author {
          justify-content: center;
          position: relative;
          max-width: 100px;
          margin: 0 auto;

          & .image {
            width: 100px;
            height: 100px;
            transition: box-shadow 300ms;
          }
        }

        & .verified {
          // position: relative;
          right: 0;
          bottom: 0;
          height: 25px;
        }
      }

      & h4 {
        font-size: 1.2rem;
      }
    }

    &:hover {
      top: -0.15rem;

      .author-wrapper {
        top: -1.5rem;

        & .avatar {
          box-shadow: 0 5px 10px rgba(14, 49, 139, 0.6);
        }
      }
    }
  }

  & .asset-type {
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #451CCEd9;
    height: 1.75rem;
    width: 1.75rem;
    text-align: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.6);

  }

  & .nft-sale-status {
    display: flex;
    z-index: 3;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    color: #fff;
    padding: 0.33rem 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 0.75rem;
    // width: 100%;
    justify-content: center;

    &.none {
      display: none;
    }

    & img {
      margin-right: 0.5rem;
    }

    &.not_started {
      // yellowy
      background-color: rgba(226, 164, 0, 0.95);
    }

    &.in_progress,
    &.sale {
      // green
      background-color: rgba(46, 142, 75, 0.95);
    }

    &.ended {
      // black or red?
      background-color: rgba(100, 0, 0, 0.8);
    }
  }

  &:hover {
    .author-wrapper {
      .author {
        &::before {
          opacity: 1;
          transform: scale(1);
        }

        &::after {
          height: calc(5rem + 4px);
          width: calc(5rem + 4px);
          top: -5px;
          left: -5px;
          background-color: transparent;
          box-shadow: 0 5px 10px rgba(14, 49, 139, 0.5);
        }
      }
    }
  }

  & .collection-stats {
    font-size: 0.75rem;
    // display: grid;
    // justify-items: center;
    text-align: center;
    // gap: 0.5rem;
    margin-top: 0.5rem;
  }
}

.nft-activity {
  position: relative;
  z-index: 12;
  display: grid;
  gap: 0.75rem;

  &.split {
    @media (min-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  & .item {
    display: grid;
    grid-template-areas: 'action action'
      'title title'
      'image image'
      'description description'
      'authorTo authorTo'
      'linkTo linkTo';
    grid-template-columns: minmax(0, auto) minmax(0, auto);
    gap: 0.5rem 2rem;
    align-items: center;
    padding: 0.75rem 1.5rem;
    border: 1px solid var(--primary-color);
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

    @media (min-width: 767px) {
      grid-template-areas:
        'action action action'
        'title title title'
        'image description authorTo'
        'image linkTo linkTo';
      grid-template-columns: 1fr 1fr;
    }

    & h3,
    & h4,
    & h5,
    & p {
      margin: 0;
    }

    & .owner {
      grid-area: owner;
    }

    & .image {
      grid-area: image;
      max-height: 200px;
    }

    & .nft-standard-logo {
      position: absolute;
      right: 1rem;
      margin: auto;
      max-width: 25px;
      max-height: 25px;
      top: 1rem;
    }

    & .title {
      grid-area: title;
      text-align: center;
      position: relative;
      line-height: 1;
      padding: 0 2rem;

      & .nft-standard-logo {
        top: 50%;
        bottom: 50%;
        right: 0;
      }
    }



    & .action {
      grid-area: action;
      text-align: center;
      color: var(--primary-color);
      margin-bottom: 0.5rem;
    }

    & .description {
      grid-area: description;
      text-align: center;
      padding: 0.25rem 0.5rem;
      margin: 0;

      & .date {
        display: block;
        font-size: 1.1rem;
        font-weight: 500;
      }

      & > p {
        margin-bottom: 0.5rem;
      }
    }

    & .linkTo {
      grid-area: linkTo;
    }

    & .to {
      grid-area: authorTo;
    }

    & .owner,
    & .to {
      display: grid;
      align-items: center;
      gap: 0.25rem;

      & .label {
        text-align: center;
        font-size: 0.9rem;
        color: var(--secondary-color);
      }

      &.span-2 {
        grid-column: span 2;
      }

      & img {
        max-height: 40px;

        &.verified {
          width: 20px;
        }
      }

      & .author-name {
        font-weight: 600;
      }
    }
  }

  &.mini .item {
    grid-template-areas:
      'description description'
      'authorTo authorTo'
      'action linkTo';
    border-bottom: 1px solid var(--primary-color);
    padding-bottom: 1rem;
    text-align: center;
    justify-items: center;

    @media (min-width: 767px) {
      grid-template-areas:
        'description description'
        'authorTo authorTo'
        'action linkTo';
      grid-template-columns: 1fr 1fr;
    }

    & .author {
      & img {
        max-height: 2.5rem;
      }
    }
  }
}
