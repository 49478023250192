.hero-section {
  position: relative;
  // top: -2px; // Removes weird white top border
  // background-color: var(--footer-bg-color);
  z-index: 1;
  text-align: center;
  background-image: url('../../assets/img/hero-mobile.webp');
  background-repeat: no-repeat;
  background-position: left center;
  background-attachment: initial;
  background-size: cover;
  padding: 8rem 0 0;
  border-bottom: 3px solid var(--secondary-color);

  @media(min-width: 767px) {
    background-image: url('../../assets/img/hero.webp');
    background-position: center bottom;
    background-attachment: fixed;
    padding-top: 10rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: rgba(47, 0, 73, 0.33);
  }

  & .content {
    position: relative;
    color: var(--white-color);

    & .symbol {
      position: fixed;
      z-index: -1;
      top: 10rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      max-width: 90%;
      max-height: 275px;
      opacity: 0.60;

      @media(min-width: 767px) {
        top: 12rem;
      }
    }

    & .logo {
      z-index: -1;
      right: 0;
      margin: 0 auto;
      max-width: 90%;
      position: relative;

      @media(min-width: 462px) {
        max-width: 400px;
      }
    }

    & h1 {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 1.3;
      margin: 0 auto;
      max-width: 500px;

      @media(min-width: 767px) {
        font-size: 3rem;
      }
    }

    & h2 {
      font-size: 1.85rem;
      font-weight: 500;
      margin-top: 9rem;

      @media(min-width: 767px) {
        font-size: 2.2rem;
      }
    }

    & h3 {
      font-size: 1.2rem;
      font-weight: 500;
      font-style: italic;
      background: rgba(10, 10, 10, 0.6);
      display: block;
      padding: 0.5rem;
      max-width: 550px;
      width: 100%;
      margin: 1rem auto 0;
      border-radius: 1rem;
      border: 3px solid var(--secondary-color);
      text-align: center;
      line-height: 1.5;
      color: #fff;

      @media(min-width: 767px) {
        font-size: 1.3rem;
      }

      & a {
        color: inherit;
        font-size: inherit;
      }
    }

  }

  & #Spotlight {
    margin: 3rem 0 0;
    color: white;
    min-height: 575px;
    overflow: hidden;

    @media(min-width: 767px) {
      margin-top: 6rem;
      min-height: initial;
      max-height: 450px;
    }

    & .symbol {
      max-height: 60px;
      margin-right: 0.5rem;
    }

    & .content {
      background: rgb(14, 14, 40);
      background: linear-gradient(180deg, rgba(14, 14, 40, 1) 6%, rgba(14, 14, 40, 0.4962359943977591) 75%);
      padding: 2rem 1rem 1rem;
      width: 100%;
      height: 100%;
      // border-radius: 1rem 1rem 0 0;
      border-top: 2px solid white;
    }

    & h2 {
      display: flex;
      align-items: center;
      // justify-content: center;
      margin: 0 auto;
      color: #fff;
      font-size: 2rem;
      line-height: 1.5;
      font-weight: 500;
      background: #0E0E28;
      width: max-content;
      padding: 0.5rem;
      border-radius: 1rem 1rem 0 0;
      border: 2px solid white;
      border-bottom: 2px solid #0E0E28;
      position: relative;
      top: 3px;
      z-index: 3;

      & svg {
        font-size: 3rem;
      }

      & span {
        margin: 0 1rem;
      }
    }

    & .collection {
      display: grid;

      &.nation {
        grid-template-areas:
          'image extra'
          'title title'
          'description description';

        @media (min-width: 767px) {
          grid-template-areas:
            'image title extra'
            'image description extra';
          grid-template-rows: 30px minmax(0, 250px);
        }
      }

      gap: 1rem;
      justify-items: center;
      justify-content: center;
      max-width: 400px;
      max-height: 450px;
      margin-left: auto;
      margin-right: auto;
      grid-template-rows: 175px minmax(min-content, max-content) minmax(0, max-content);

      @media (min-width: 767px) {
        grid-template-columns: minmax(0, 0.5fr) minmax(0, 1fr) minmax(0, 0.5fr);
        align-items: center;
        max-width: 1200px;
        max-height: 300px;
      }

      & img {
        max-height: 100%;
        width: 100%;
        height: 100%;
        // object-fit: contain;
      }

      & .image-wrapper {
        grid-area: image;
        height: 100%;

        &.full {
          grid-column: 1/-1;
          grid-row: 1/-1;
          width: 100%;
        }

        & img {
          border: 2px solid #c2c2c246;
          border-radius: 1rem;
          object-fit: cover;
        }

        &.extra {
          grid-area: extra;

          & img {
            border: 2px solid rgba(171, 0, 169, 0.498);
          }
        }

        &.solo {
          grid-column: span 2;
        }



        &.small {
          max-width: 100px;
        }

      }

      & .title {
        grid-area: title;
        color: white;
        margin: 0;
        line-height: 1;
        text-decoration: underline;

        &.text-left {
          justify-self: flex-start;
        }
      }

      & .description {
        grid-area: description;

        &.contained {
          max-width: 450px;
        }

        // align-content: center;
      }

      &.custom {
        & img {
          object-fit: contain;
        }
      }

      & > .btn {
        grid-column: 1/-1;
        grid-row: 2;
        align-self: flex-end;
      }

      & .btn {
        font-weight: 600;
        max-width: 300px;
        margin: 0 auto 1.5rem;

        @media (min-width: 767px) {
          align-self: flex-end;
        }
      }
    }

    &--Carousel {
      padding-bottom: 2.5rem;
      max-height: 450px;
      height: 100%;

      & .carousel-item {
        min-height: 265px;
        max-height: 100%;

        &.active,
        &-next {
          display: grid;
        }

        &.dragon {
          text-align: center;

          & .title {
            margin: 1rem 0 0;
          }

          & .description {
            max-width: 500px;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 1rem;
            padding: 0.75rem 1.5rem;
            margin: 0;
          }

          // DRAGON OVERRIDES
          & .collection {
            grid-template-areas:
              'title'
              'description'
              'btn';
            grid-template-rows: minmax(min-content, max-content) auto 1fr;
            grid-template-columns: auto;
            align-items: flex-start;
            gap: 1.5rem;

            @media (min-width: 767px) {
              gap: 0.5rem;
              grid-template-rows: minmax(min-content, max-content) 1fr auto;
            }

            & .btn {
              grid-area: btn;
            }
          }
        }
      }

      & .carousel-indicators {
        bottom: -0.5rem;
      }
    }
  }

  &.admin {
    background-image: url('../../assets/img/hero2.webp');
    padding: 8rem 0 9rem;

    &::before {
      // background: transparent;
      background: rgba(47, 0, 73, 0.15);
    }

    & #Spotlight .collection.custom {
      display: grid;
      // justify-content: center;
    }
  }
}
