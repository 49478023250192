#Analytics {
  & dl {
    flex: 1;
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    // justify-content: space-evenly;
    text-align: center;

    & dt {
      margin: 0 0 0.25rem;
    }

    @media (min-width: 575px) {
      // grid-template-columns: 1fr 1fr;
      grid-template-columns: repeat(auto-fit, minmax(25%, 30%));
    }

    @media (min-width: 767px) {
      grid-template-columns: repeat(auto-fit, minmax(0, 175px));
    }
  }

  & > div {
    text-align: center;
    column-gap: 1rem;
    border-bottom: 1px solid purple;
    padding: 0 0 1rem;

    @media (min-width: 767px) {
      display: flex;
      align-items: center;
    }
  }

  & .author-name {
    font-weight: 400;
    color: var(--secondary-color);
  }

  & > div {
    display: grid;
    text-align: left;
    align-items: center;
    row-gap: 0.5rem;

    & svg,
    & h4 {
      grid-row-start: 1;
      grid-column-start: 1;
    }

    & h4 {
      margin: 0 0 0 4rem;
    }

    & .icon {
      @media (max-width: 767px) {
        font-size: 1rem;
      }
    }
  }
}
